// src/pages/SearchPage.tsx
import React, { useState } from 'react';

const SearchPage = () => {
  const [query, setQuery] = useState('');

  const handleSearch = () => {
    // Placeholder function to handle the search action
    console.log(`Searching for: ${query}`);
    // You can add actual search functionality here in the future
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Search Destinations</h2>
      <div className="flex items-center space-x-2">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Enter a destination"
          className="border rounded px-4 py-2 w-full"
        />
        <button
          onClick={handleSearch}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Search
        </button>
      </div>
      <p className="mt-4 text-gray-600">Results will appear here...</p>
    </div>
  );
};

export default SearchPage;
