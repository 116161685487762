import React, { useState, useEffect } from 'react';
import beach from '../assets/beach.jpg';
import books from '../assets/books.jpg';
import flamingo from '../assets/flamingo.jpg';
import palms from '../assets/palms.jpg';
import cabin from '../assets/cabin.jpg';

const HomePage = () => {
  const [displayText, setDisplayText] = useState('');
  const fullText = "D iscover beautiful destinations and plan your next adventure!";

  useEffect(() => {
    let index = 0;

    const interval = setInterval(() => {
      if (index < fullText.length) {
        setDisplayText((prev) => prev + fullText.charAt(index));
        index++;
      } else {
        clearInterval(interval); // Stop interval when all characters are added
      }
    }, 50); // Adjust typing speed

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <div className="text-center">
      <p className="mb-6 text-customPurple">{displayText}</p>

      {/* Search Box Section */}
      <div className="mb-8">
        <input
          type="text"
          placeholder="Search for destinations, activities, or experiences..."
          className="border-2 border-customPurple animate-fade rounded-lg px-4 py-2 w-3/4 sm:w-1/2 focus:outline-none focus:ring-1 focus:ring-customPurple"
        />
        <button
          className="ml-2 bg-customPurple animate-fade text-white px-4 py-2 rounded-lg hover:bg-opacity-90 transition-colors"
        >
          Search
        </button>
      </div>

      {/* Image Grid Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 animate-fade">
        <img
          src={beach}
          alt="Beach destination"
          className="rounded-lg shadow-lg w-full h-64 object-cover transition-transform transform hover:scale-105"
        />
        <img
          src={books}
          alt="Books"
          className="rounded-lg shadow-lg w-full h-64 object-cover transition-transform transform hover:scale-105"
        />
        <img
          src={cabin}
          alt="Cabin"
          className="rounded-lg shadow-lg w-full h-64 object-cover transition-transform transform hover:scale-105"
        />
        <img
          src={flamingo}
          alt="Flamingo"
          className="rounded-lg shadow-lg w-full h-64 object-cover transition-transform transform hover:scale-105"
        />
      </div>
    </div>
  );
};

export default HomePage;
