// src/pages/ItineraryPage.tsx
import React from 'react';

const ItineraryPage = () => {
  return (
    <div>

    </div>
  );
};

export default ItineraryPage;
