import React from 'react';

const Header = () => {
  return (
    <header className="bg-white-200 text-customPurple font-bold p-4">
      <nav className="container mx-auto flex justify-start items-center">
        <div className="flex items-center space-x-2">
          <img src="/Tripzion-Logo-Final.png" alt="TripZion Logo" className="h-20 w-20 animate-fade" />
        </div>
        <ul className="flex items-start space-x-4 ml-4 animate-fade">
          <li><a href="/" className="hover:underline">Home</a></li>
          <li><a href="/search" className="hover:underline">Search</a></li>
          <li><a href="/itinerary" className="hover:underline">Itinerary</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
